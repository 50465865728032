//sticky header for main navbar

var navBarMain = $(".navbar__main");
var stickyDiv = "sticky";
var utilityHeader = $('.navbar__utility').outerHeight();

var navAdjust = "navbar__main--adjust";

$(window).scroll(function () {
    if ($(this).scrollTop() > utilityHeader) {
        navBarMain.addClass(stickyDiv);
    } else {
        navBarMain.removeClass(navAdjust);
        navBarMain.removeClass(stickyDiv);
    }
});


//Detecting up scroll for utility navbar (work)

// var navbarUtility = $(".navbar__utility");
// var stickyUtil = "sticky--utility";
// var lastScrollTop = 0;
//     $(window).on('scroll', function() {
//         var st = $(this).scrollTop();

//         //if you're scrolling up AND you are scrolled past utility nav adjust the main nav and peek in the util
//         if(st < lastScrollTop && $(this).scrollTop() > utilityHeader) {
//             navBarMain.addClass(navAdjust);
//             navbarUtility.addClass(stickyUtil);
//         }

//         //if you're scrolling up AND you have reached the top of the page
//         else if(st < lastScrollTop && $(this).scrollTop() > utilityHeader){
//             navbarUtility.removeClass(stickyUtil); 

//         } 

//         //if you're scrolling down AND after util header you push main nav to the top and remove the sticky
//         else if(st > lastScrollTop && $(this).scrollTop() > utilityHeader){
//             navBarMain.removeClass(navAdjust);
//             navbarUtility.removeClass(stickyUtil); 
//         } 

//         else{

//         }
//         lastScrollTop = st;
//     });