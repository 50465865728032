const config = {
  apiPaths: {
    retailers: "/api/Insights/GetRetailers",
    postRetailers: "/api/Accounts/AddUserPreference",
    selectionRetailers: "/api/Insights/GetRetailers?needRetailerURL=true",
    channels: "/api/Insights/GetChannelCountries",
    postChannels: "/api/Accounts/AddUserPreference",
    markets: "/api/Insights/GetMarkets",
    postMarkets: "/api/Accounts/AddUserPreference",
    experts: "/api/Insights/GetExperts",
    toggleFollowExpert: "/api/Accounts/AddUserFollowedExpert",
    toggleBookmark: "/api/Accounts/AddUserSavedCard",
    toggleQuicklink: "/api/Accounts/AddUserQuickLink",
    removeMySaved: "/api/Accounts/AddUserSavedCard",
    alerts: "/api/Accounts/GetUserEmailAlerts",
    postAlerts: "/api/Accounts/UpdateUserEmailAlerts",
    savedInsights: "/api/SavedInsights/GetSavedInsights",
    postSavedInsights: "/api/SavedInsights/GetSavedInsights",
    moreSavedInsights: "/api/SavedInsights/GetSavedInsights",
    postUpdateAvatar: "/api/Accounts/UpdateAvatar",
    postToggleRetailerPreference: "/api/Accounts/AddUserPreference",
    logoffUser: "/auth/logout"
  },
  entityTypes: {
    retailer: "RETAILER",
    channel: "CHCOUNTRY",
    market: "MARKET"
  }
}

function getRetailers(callback) {
  $.ajax({
    url: config.apiPaths.retailers,
    type: "GET",
    dataType: "json"
  }).always(function (data, textStatus, jqXHR) {
    var isSuccess = jqXHR.status === 200;
    callback(data, isSuccess);
  });
}

function getInsightSelectionRetailers(callback) {
  $.ajax({
    url: config.apiPaths.selectionRetailers,
    type: "GET",
    dataType: "json"
  }).always(function (data) {
    callback(data);
  });
}

function postRetailers(retailerObj, callback) {
  var obj = JSON.parse('{ "itemid":"' + retailerObj.Id + '", "entitytype":"' + config.entityTypes.retailer + '" }');
  
  $.ajax({
    url: config.apiPaths.postRetailers,
    type: "POST",
    dataType: "json",
    data: obj,
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    var isSuccess = data !== void 0 && (data.hasOwnProperty('SqlResponse') && data.SqlResponse.ResponseCode != -1 || data.status === 200);
    callback(isSuccess, JSON.stringify(data));
  });
}

function getChannels(callback) {
  $.ajax({
    url: config.apiPaths.channels,
    type: "GET",
    dataType: "json"
  }).always(function (data, textStatus, jqXHR) {
    var isSuccess = jqXHR.status === 200;
    callback(data, isSuccess);
  });
}

function postChannels(channelObj, callback) {
  var obj = JSON.parse('{ "itemid":"' + channelObj.Id + '", "entitytype":"' + config.entityTypes.channel + '" }');
  $.ajax({
    url: config.apiPaths.postChannels,
    type: "POST",
    dataType: "json",
    data: obj,
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    var isSuccess = jqXHR.status === 200;
    callback(data, isSuccess);
  });
}

function getMarkets(callback) {
  $.ajax({
    url: config.apiPaths.markets,
    type: "GET",
    dataType: "json"
  }).always(function (data, textStatus, jqXHR) {
    var isSuccess = jqXHR.status === 200;
    callback(data, isSuccess);
  });
}

function postMarkets(marketObj, callback) {
  var obj = JSON.parse('{ "itemid":"' + marketObj.Id + '", "entitytype":"' + config.entityTypes.market + '" }');
  $.ajax({
    url: config.apiPaths.postMarkets,
    type: "POST",
    dataType: "json",
    data: obj,
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    var isSuccess = data !== void 0 && (data.hasOwnProperty('SqlResponse') && data.SqlResponse.ResponseCode != -1 || data.status === 200);
    callback(isSuccess, JSON.stringify(data));
  });
}

function getExperts(callback) {
  $.ajax({
    url: config.apiPaths.experts,
    type: "GET",
    dataType: "json"
  }).always(function (data, textStatus, jqXHR) {
    var isSuccess = jqXHR.status === 200;
    callback(data, isSuccess);
  });
}

function toggleFollowExpert(isFollow, expertId, callback) {
  $.ajax({
    url: config.apiPaths.toggleFollowExpert,
    type: "POST",
    dataType: "json",
    data: {
      expertitemid: expertId,
      isFollowing: isFollow
    },
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    var isSuccess = data !== void 0 && (data.hasOwnProperty('SqlResponse') && data.SqlResponse.ResponseCode != -1 || data.status === 200);
    callback(isSuccess, JSON.stringify(data));
  });
}

function toggleBookmark(contentId, contentUrl = null, contentTitle = null, callback) {
  $.ajax({
    url: config.apiPaths.toggleBookmark,
    type: "POST",
    dataType: "json",
    data: {
      itemid: contentId,
      title: contentTitle,
      url: contentUrl
    },
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    var isSuccess = data !== void 0 && (data.hasOwnProperty('SqlResponse') && data.SqlResponse.ResponseCode != -1 || jqXHR.status === 200);
    callback(isSuccess, data);
  });
}

function toggleQuicklink(contentId, contentUrl = null, contentTitle = null, callback) {
  $.ajax({
    url: config.apiPaths.toggleQuicklink,
    type: "POST",
    dataType: "json",
    data: {
      itemid: contentId,
      title: contentTitle,
      url: contentUrl
    },
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    var isSuccess = data !== void 0 && (data.hasOwnProperty('SqlResponse') && data.SqlResponse.ResponseCode != -1 || jqXHR.status === 200);
    callback(isSuccess, data);
  });
}

function removeMySaved(savedCardId, callback) {
  $.ajax({
    url: config.apiPaths.removeMySaved,
    type: "POST",
    dataType: "json",
    data: {
      itemid: savedCardId
    },
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    callback(data);
  });
}

function getAlerts(callback) {
  $.ajax({
    url: config.apiPaths.alerts,
    type: "GET",
    dataType: "json"
  }).always(function (data, textStatus, jqXHR) {
    var isSuccess = jqXHR.status === 200;
    callback(data, isSuccess);
  });
}

function postAlerts(alertsObj, callback) {
  $.ajax({
    url: config.apiPaths.postAlerts,
    type: "POST",
    dataType: "json",
    data: alertsObj,
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    callback(data.statusText);
  });
}

function getSavedInsights(callback) {
  $.ajax({
    url: config.apiPaths.savedInsights,
    type: "GET",
    dataType: "json",
  }).always(function (data) {
    callback(data);
  });
}

function postSavedInsights(searchTerm, callback) {
  $.ajax({
    url: config.apiPaths.postSavedInsights,
    type: "POST",
    dataType: "json",
    data: {
      searchTerm: searchTerm
    }
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    callback(data);
  });
}

function getMoreSavedInsights(searchTerm, pageLength, callback) {
  $.ajax({
    url: config.apiPaths.moreSavedInsights,
    type: "POST",
    dataType: "json",
    data: {
      pageLength: pageLength,
      searchTerm: searchTerm
    }
  }).always(function (data, textStatus) { // eslint-disable-line no-unused-vars
    callback(data);
  });
}

function postToggleRetailerPreference(retailerId, callback) {
  $.ajax({
    url: config.apiPaths.postToggleRetailerPreference,
    type: "POST",
    dataType: "json",
    data: {
		ItemId: retailerId,
		EntityType: "Retailer"
    }
  }).always(function (data, textStatus) { // eslint-disable-line no-unused-vars
    callback(data);
  });
}

function postUpdateAvatar(formData, $btn, callback) {
  $.ajax({
    url: config.apiPaths.postUpdateAvatar,
    type: 'POST',
    context: $btn,
    data: formData,
    processData: false,
    contentType: false,
  }).always(function (data, textStatus, jqXHR) { // eslint-disable-line no-unused-vars
    
    // pass back whether or not there is a server error or a validation error
    let isServerError = jqXHR.status !== 200 || data.hasOwnProperty('IsServerError') && data.IsServerError;
    let isValidationError = data !== void 0 && data.hasOwnProperty('IsValidationError') && data.IsValidationError;

    callback(isServerError, isValidationError);
  });
}

function logoffUser() {
	$(location).attr('href', config.apiPaths.logoffUser);
}

exports.config = config;
exports.getRetailers = getRetailers;
exports.postRetailers = postRetailers;
exports.getInsightSelectionRetailers = getInsightSelectionRetailers;
exports.getChannels = getChannels;
exports.postChannels = postChannels;
exports.getMarkets = getMarkets;
exports.postMarkets = postMarkets;
exports.getExperts = getExperts;
exports.toggleFollowExpert = toggleFollowExpert;
exports.toggleQuicklink = toggleQuicklink;
exports.removeMySaved = removeMySaved;
exports.toggleBookmark = toggleBookmark;
exports.getAlerts = getAlerts;
exports.postAlerts = postAlerts;
exports.getSavedInsights = getSavedInsights;
exports.postSavedInsights = postSavedInsights;
exports.getMoreSavedInsights = getMoreSavedInsights;
exports.postToggleRetailerPreference = postToggleRetailerPreference;
exports.postUpdateAvatar = postUpdateAvatar;
exports.logoffUser = logoffUser;
