$('#mobileToggle').click(function () {
  var $mobileToggle = $(this);
  var $mobileNavBar = $mobileToggle.closest('.navbar__mobile');

  $mobileToggle.toggleClass('active');
  $('#overlay').toggleClass('open');

  $('body').toggleClass('freeze-body');

  if (window.KRIQ.UI.IsSearchBoxStatic) {
    $mobileNavBar.toggleClass('searchbox-static');
  }
});

$(".auth--expandable").on("click", function () {
  var thisElem = $(this);

  //Prevent user from double clicking arrows
  thisElem.addClass('auth--expandable-disabled');

  setTimeout(function () {
    thisElem.removeClass('auth--expandable-disabled');
  }, 400);


  // Remove the active class from all other arrows
  $('.auth--expandable').removeClass('auth--expandable-flip');
  $('.card').removeClass('card--auth-active');

  if (thisElem.hasClass('collapsed')) {
    thisElem.addClass('auth--expandable-flip');
    thisElem.closest('.card').addClass('card--auth-active');
  } else {
    thisElem.removeClass('auth--expandable-flip');
    thisElem.closest('.card').removeClass('card--auth-active');
  }
});

$('.hamburger--mobile').on("click", function () {
  $('.flyout-overlay-mobile').toggleClass("overlay-active");
});

$('.flyout-overlay-mobile').on("click", function () {
  $(this).toggleClass("overlay-active");
  $('#overlay').toggleClass('open');
  $('body').toggleClass('freeze-body');
  $('navbar__mobile').toggleClass('freeze-body');
  $('#mobileToggle').toggleClass('active');
});