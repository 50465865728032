import { logoffUser } from '../utils/utils.dataService';

//Get Value of what the current tile should be based on current page
let currentTile = '#tile-' + $('.sidebar--currentPage').attr("id");

//Set the correct tile to active
function setTile(){
    $('.sidebar--list li.sidebar--tile_active').removeClass('sidebar--tile_active');
    $(currentTile).addClass("sidebar--tile_active");
}

$(document).ready(function () {
    setTile();
})

$(".sidebar--tile").on("click", function () {

    //Get the correct flyout associated with the clicked tile

    var elemID = '#' + $(this).attr("id");
    var sidebar = elemID + '__flyout'

    $(sidebar).toggleClass("active").siblings().removeClass('active');
    $('.sidebar--list li.sidebar--tile_active').removeClass('sidebar--tile_active');
    $(this).toggleClass("sidebar--tile_active");

    if ($(sidebar).hasClass("active") === true) {
        $(".flyout-overlay").addClass("overlay-active");
        $(".flyout-wrapper").removeClass("wrapper-inactive");
    } else {
        $(".flyout-overlay").removeClass("overlay-active");
        $(".flyout-wrapper").addClass("wrapper-inactive");
        setTile();
    }
});

//Prevent scrolling of page when overlay is active

$('.sidebar--tile.expandable').on("click", function () {
    $('body').toggleClass('freeze-body');
});

$(".flyout-overlay").on("click", function () {

    //Remove flyout overlay and unactive the sidebar flyout

    $(".flyout-overlay").siblings().removeClass("active");
    $(".flyout-overlay").removeClass("overlay-active");
    $(".flyout-wrapper").addClass("wrapper-inactive");
    $('body').removeClass('freeze-body');

    setTile();
});

var resizeTimeout;
$(window).resize(function(){
  if(!!resizeTimeout){ clearTimeout(resizeTimeout); }
  resizeTimeout = setTimeout(function(){
    
     //Desktop
    
     $(".flyout-overlay").siblings().removeClass("active");
     $(".flyout-overlay").removeClass("overlay-active");
     $(".flyout-wrapper").addClass("wrapper-inactive");
     $('body').removeClass('freeze-body');
 
     setTile();
 
     //Mobile
 
     $('.flyout-overlay-mobile').removeClass("overlay-active");
 
     $('#overlay').removeClass('open');
     $('body').removeClass('freeze-body');
     $('navbar__mobile').removeClass('freeze-body');
     $('#mobileToggle').removeClass('active');

  },200);
});

// Logoff user
$('.author-logout').on("click", function () {
    logoffUser();
});